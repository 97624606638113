import React, { useCallback, useEffect, useState } from "react";
import '../../css/login-page.css';
import { Images } from "../../assets";
import { checkBillExist, getConfig } from "../../services/apis";
import { Button, Divider, Input, List, Row, Space, message } from 'antd';
import LoadingBase from "../../components/LoadingBase";
import { useDispatch, useSelector } from "react-redux";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import moment from "moment";
import { formatNumber } from "../../utils/funcHelper";
import { saveUserInfo } from "../../redux/action";
import { SHEET_APP_ID_DEFAULT } from "../../constants/constants";
import { debounce } from "lodash";

const CheckBillPage = () => {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const userReducer = useSelector(state => state?.userReducer);
    const [status, setStatus] = useState(); //1: Hop le, 2: Khong ho le
    const [data, setData] = useState();
    const [staffName, setStaffName] = useState();
    const [dataInvoice, setDataInvoice] = useState();
    const dispatch = useDispatch();

    let barcodeScan = "";
    const handleChangeCode = (e) => {
        setCode(e.target.value);
        setStatus(0)
    }

    const getDataConfig = async () => {
        try {
            setLoading(true);
            const rs = await getConfig();
            setLoading(false);
            consoleLogUtil("getDataConfig", rs);
            if (rs && rs?.status === 1) {
                dispatch(saveUserInfo({
                    ...userReducer?.data,
                    config: rs.data
                }));
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleGetStaffInfo = async () => {
        const params = {
            client_id: userReducer?.data?.branch_info?.client_id,
            client_secret: userReducer?.data?.branch_info?.client_secret,
            grant_type: "client_credentials",
            scopes: "PublicApi.Access",
            retailer: userReducer?.data?.branch_info?.retailer,
            code: code
        };
        setLoading(true);
        fetch(`https://script.google.com/macros/s/${userReducer?.data?.config?.sheet_app_id ?? SHEET_APP_ID_DEFAULT}/exec?type=GET_INVOICE_DETAIL`, {
            redirect: "follow",
            method: 'POST',
            body: JSON.stringify(params),
        }).then(response => response.json())
            .then(json => {
                setLoading(false);
                setDataInvoice({ ...json });
                if (json && json?.soldByName) {
                    setStaffName(json?.soldByName);
                } else if (json?.responseStatus && json?.responseStatus?.message) {
                    setStaffName('');
                    message.warning(json?.responseStatus?.message);
                } else {
                    setStaffName('');
                    message.warning("Không tìm thấy tên nhân viên của số hoá đơn này");
                }
            })
            .catch(error => {
                setLoading(false);
                setStaffName();
                message.error("Không thể kết nối với kiotviet để lấy thông tin")
            });

    }

    const handleCheckBill = async (numb) => {
        if (!numb || numb?.length === 0) {
            message.warning("Vui lòng nhập số hoá đơn");
            return;
        }
        setStatus(0);
        setStaffName();
        try {
            const params = {
                code: numb,
                branchId: userReducer?.data?.branch_info?.id,
                checkById: userReducer?.data?.user_info?.id
            };
            setLoading(true);
            const res = await checkBillExist(params);
            setLoading(false);
            consoleLogUtil("checkBillExist", res);
            if (res) {
                if (res?.status === 1) { //Hoa don hop le
                    setStatus(1);
                } else if (res?.status === 2) { //Hoa don da check in truoc day
                    setStatus(2);
                    setData(res?.data);
                } else {
                    setStatus(3);
                }
            } else {
                setStatus(3);
            }
        } catch (error) {
            consoleLogUtil("errrror", error)
            setLoading(false);
            setStatus(3);
        }

    }
    const debouncedHandleCheckBill = useCallback(debounce(handleCheckBill, 500), []);

    const handleScan = (barcodeString) => {
        if (loading) return;
        // setCode(barcodeString);
        // setStatus(0);
        // // handleCheckBill(barcodeString);
        // debouncedHandleCheckBill(barcodeString);
        setCode(prevCode => {
            if (prevCode !== barcodeString) {
                setStatus(0);
                debouncedHandleCheckBill(barcodeString);
                return barcodeString;
            }
            return prevCode;
        });
        barcodeScan = "";
    }
    useEffect(() => {
        getDataConfig();
        function handleKeyDown(e) {
            //remove specia key: mũi tên, caplock, shift, backspace, F1234...
            const specialKeys = [
                8, 9, 16, 17, 18, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46,
                91, 92, 93, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123
            ];
        
            // Ignore special keys
            if (specialKeys.includes(e.keyCode)) {
                return;
            }
            if (e.keyCode === 13 && barcodeScan.length > 7 && barcodeScan !== "Enter" && !loading) {
                const regex = /^HD\d{6}$/; // Regular expression to match "HD" followed by 6 digits
                if (regex.test(barcodeScan)) {
                    handleScan(barcodeScan);
                }
                return;
            }
            
            barcodeScan += e.key;
            setTimeout(() => {
                barcodeScan = "";
            }, 100)
        }
        document.addEventListener('keydown', handleKeyDown);

        return function cleanUp() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])
    return (
        <div className='container-content'>
            <Row>
                <Space>
                    <p style={{ fontSize: 18, fontWeight: 600, color: 'black' }}>Mã vận đơn</p>
                    <Input value={code} onChange={handleChangeCode} style={{ fontSize: 20, fontWeight: 800, width: 300, height: 48, color: 'black' }} />
                    <Button type="primary" onClick={() => handleCheckBill(code)} style={{ height: 48, width: 120, fontSize: 18 }}>Kiểm tra</Button>
                </Space>
            </Row>
            <Divider orientation="left" style={{ marginTop: 30 }}>Kết quả kiểm tra</Divider>
            {
                status === 1 ?
                    <Row align={"middle"}>
                        <img src={Images.ic_status} style={{ width: 40, height: 40 }} />
                        <p style={{ color: '#4cae4f', fontSize: 20, fontWeight: 800, paddingLeft: 12 }}>Mã vận đơn hợp lệ</p>
                    </Row>

                    : null
            }
            {
                status === 2 ?
                    <>
                        <Row align={"middle"}>
                            <img src={Images.ic_error} style={{ width: 40, height: 40 }} />
                            <p style={{ color: 'red', fontSize: 20, fontWeight: 800, paddingLeft: 12 }}>Mã vận đơn {code} không hợp lệ do đã từng quét</p>
                        </Row>
                        <Row align={"middle"} style={{ marginTop: 12 }}>
                            {/* <img src={Images.ic_dates} style={{ width: 24, height: 24 }} /> */}
                            <p style={{ color: 'black', fontSize: 16, paddingLeft: 0 }}>Thời gian quét trước đây: {data?.check_in ? <span style={{ fontSize: 16, fontWeight: 700, color: 'black' }}>{moment(data?.check_in).format("HH:mm:ss DD/MM/YYYY")}</span> : ''}</p>

                        </Row>
                        
                        {/* <div style={{ marginTop: 50 }}>
                            <Button danger type="primary" onClick={handleGetStaffInfo}>Lấy thông tin đơn hàng</Button>
                        </div> */}
                    </>

                    : null
            }
            

            <LoadingBase loading={loading} />
        </div>

    );
}


export default CheckBillPage;