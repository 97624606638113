import { Button, Col, Form, Input, Row, Space, theme, Table, message, Modal, Select} from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { TYPE_MODAL, requiredItem } from '../../constants/constants';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { useSelector } from 'react-redux';
import { addNewUser, getListAccount, getListBranch, updateUserInfo } from '../../services/apis';

const ListAccountPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [formUser] = Form.useForm();
    const [data, setData] = useState([]);
    const [dataDepart, setDataDepart] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [typeModal, setTypeModal] = useState();
    const [loadingModal, setLoadingModal] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [modal, contextHolder] = Modal.useModal();
    const PASWORD_DEFAULT = "ajdkfasdf234@@";
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            key: 'username',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Họ tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Chi nhánh làm việc',
            dataIndex: 'branch_name',
            key: 'branch_name',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Đối tượng',
            dataIndex: 'type',
            key: 'type',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text === "1" ? "Admin" :  "Nhân viên"}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => record?.type !== 1 ? <a onClick={() => handleEdit(record)} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>Sửa</a> : null
        },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    
    const handleEdit = (item) => {
        setItemSelected(item);
        setShowModal(true);
        setTypeModal(TYPE_MODAL.EDIT);
        formUser.setFieldsValue({
            username: item.username,
            fullname: item?.fullname,
            password: PASWORD_DEFAULT,
            location: item.branch_id
        });
    }

    const getDataUsers = async (keyword, disable) => {
        const params = {
            keyword: keyword ?? '',
            parent_id: userReducer?.data?.user_info?.id
        }
        if (!disable) {
            setLoading(true);
        }
        const res = await getListAccount(params);
        if (!disable) {
            setLoading(false);
        }
        if (res && res?.status === 1) {
            setData([...res?.data ?? []]);
        } else {
            setData([]);
        }
    }

    const getDataBranch = async () => {
        const params = {
            limit: 100,
            currentPage: 1,
            user_id: userReducer?.data?.user_info?.id
        };
        const res = await getListBranch(params);
        if (res && res?.status === 1) {
            setDataDepart([...res?.data?.data]);
        } else {
            setDataDepart([]);
        }
    }
    const updateUser = async (dataUser) => {
        try {
            setLoading(true);
            const params = {
                user_id: itemSelected.id,
                username: dataUser.username,
                fullname: dataUser.fullname,
                type: 2,
                branch_id: dataUser.location
            };
            if (dataUser.password !== PASWORD_DEFAULT) {
                params['password'] = dataUser.password;
            }
            const res = await updateUserInfo(params);
            setLoading(false);
            consoleLogUtil("updateUserInfo", res);
            if (res && res?.status === 1) {
                message.success("Cập nhật dữ liệu thành công");
                getDataUsers('');
            } else {
                message.error(res?.msg ?? 'Có lỗi xảy ra');
            }
        } catch (error) {
            setLoading(false);
            message.error('Hệ thống có lỗi xảy ra');
        }
    }
    const onCancel = () => {
        setShowModal(false);
    }
    
    const onFinish = async (data) => {
        getDataUsers(data?.name);
    };
    const handleAddUser = () => {
        setShowModal(true);
        setTypeModal(TYPE_MODAL.ADD);
        formUser.resetFields();
    }

    const addUser = async (dataUser) => {
        try {
            setLoading(true);
            const params = {
                username: dataUser?.username,
                fullname: dataUser?.fullname,
                password: dataUser?.password,
                type: 2,
                branch_id: dataUser?.location
            };
            const res = await addNewUser(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Thêm mới tài khoản thành công");
                getDataUsers('');
            } else {
                message.error(res?.msg ?? "Có lỗi xảy ra khi thêm mới");
            }
        } catch (error) {
            setLoading(false);
            message.error(error?.message ?? "Hệ thống có lỗi xảy ra");
        }
        
    }

    const onFinishSaveUser = async (data) => {
        setShowModal(false);
        if (typeModal === TYPE_MODAL.ADD) {
            addUser(data);
        } else {
            updateUser(data);
        }
    }

    const filterOption = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataUsers('', true), getDataBranch()])
            .catch(err => consoleLogUtil("errr", err))
            .finally(() => setLoading(false))
    }, [])

    return (
        <div className='container-content'>
            {
                contextHolder
            }
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên tài khoản'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={handleAddUser}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
            />
            <LoadingBase loading={loading} />
            <Modal
                width={'80%'}
                open={showModal}
                onCancel={onCancel}
                footer={null}
            >
                <Form
                    form={formUser}
                    name="user_info"
                    style={formStyle}
                    onFinish={onFinishSaveUser}
                    layout="vertical"
                >
                    <p style={{ color: "black", fontWeight: '600', fontSize: 16, paddingBottom: 10 }}>Thông tin tài khoản</p>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                rules={requiredItem}
                                name={'username'}
                                label={'Tài khoản'}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={'fullname'}
                                label={'Họ và tên'}
                                rules={requiredItem}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        
                        <Col span={8}>
                            <Form.Item
                                name={'password'}
                                label={'Mật khẩu'}
                            >
                                <Input type='password'/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item
                            name={'location'}
                            label={'Địa điểm làm việc'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                placeholder={'Chọn địa điểm'}
                                defaultActiveFirstOption={false}
                                style={{width: 300, height: 40}}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataDepart?.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>

                    </Row>
                    
                    
                    <Row justify={"end"}>
                        <Space>
                            <Button type='primary' htmlType='submit'>Lưu</Button>
                            <Button onClick={onCancel}>Đóng</Button>
                        </Space>
                    </Row>

                </Form>
                <LoadingBase loading={loadingModal} />
            </Modal>
        </div>
    )
}
export default React.memo(ListAccountPage);