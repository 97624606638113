import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import DashboardPage from './screens/dashboard/DashboardPage';
import PrivateRoute from './components/PrivateRoute';
import ListBranchPage from './screens/category/company/ListBranchPage';
import ListAccountPage from './screens/mana-account/ListAccountPage';
import CheckBillPage from './screens/check-bill/CheckBillPage';
import ReportBillDuplicatePage from './screens/report/ReportBillDuplicatePage';
import ChangePasswordPage from './screens/change-pass/ChangePasswordPage';
import ReportBillTotalPage from './screens/report/ReportBillTotalPage';
import ReportBillDiffDatePage from './screens/report/ReportBillDiffDatePage';


const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/login" element={
                    <LoginPage />
                } />
                <Route path="/" element={
                    <PrivateRoute>
                        <CheckBillPage />
                    </PrivateRoute>
                } />
                <Route path="/check-bill" element={
                    <PrivateRoute>
                        <CheckBillPage />
                    </PrivateRoute>
                } />
                <Route path="/bao-cao-mvd-trung" element={
                    <PrivateRoute>
                        <ReportBillDuplicatePage />
                    </PrivateRoute>
                } />
                <Route path="/bao-cao-tong-hop" element={
                    <PrivateRoute>
                        <ReportBillTotalPage />
                    </PrivateRoute>
                } />
                <Route path="/quan-ly-tai-khoan" element={
                    <PrivateRoute>
                        <ListAccountPage />
                    </PrivateRoute>
                } />
                <Route path="/thay-doi-mat-khau" element={
                    <PrivateRoute>
                        <ChangePasswordPage />
                    </PrivateRoute>
                } />
   
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;