import { Button, Col, Form, Row, Space, theme, Table, message, DatePicker, Modal, List, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { getListAccount, getListBillByUserId, getReportTotal } from '../../services/apis';
import dayjs from 'dayjs';
import { filterOption, formatNumber } from '../../utils/funcHelper';

const ReportBillTotalPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataUser, setDataUser] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [dataDetail, setDataDetail] = useState([]);
    const { RangePicker } = DatePicker;
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => (index + 1) + 50 * (currentPage - 1),
        },
        {
            title: 'Nhân viên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text) => <a style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }} onClick={() => handleGetStaffInfo(text)}>{text}</a>
        },
        {
            title: 'Tổng số mã vận đơn',
            dataIndex: 'totalBills',
            align: 'center',
            key: 'totalBills',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        }
        ,
        {
            title: 'Chi tiết',
            align: 'center',
            render: (text, record) => <a style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12, textDecorationLine: 'underline' }} onClick={() => getDataDetailByStaff(record?.userId)}>Xem</a>
        }
    ];

    const columns_modal = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => (index + 1) + 50 * (currentPage - 1),
        },
        {
            title: 'Mã vận đơn',
            dataIndex: 'billCode',
            key: 'billCode',
            render: (text) => <p>{text}</p>
        },
        {
            title: 'Thời gian quét',
            dataIndex: 'checkIn',
            align: 'center',
            key: 'checkIn',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        }
        ,
        {
            title: 'Người quét',
            align: 'center',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text, record) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        }
    ];
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const handleGetStaffInfo = async (code) => {
        

    }

    const getDataUsers = async () => {
        const params = {
            keyword: '',
        }
        setLoading(true);
        const res = await getListAccount(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setDataUser([...res?.data ?? []]);
        } else {
            setDataUser([]);
        }
    }

    const getDataDetailByStaff = async (id) => {
        const params = {
            startTime: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[0])?.startOf('day').format("YYYY-MM-DD HH:mm:ss") : dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            endTime: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[1])?.endOf('day')?.format("YYYY-MM-DD HH:mm:ss") : dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            userId: id,
        };
        setLoading(true);
        const res = await getListBillByUserId(params);
        setLoading(false);
        if (res && res.status === 1) {
            setDataDetail([...res?.data]);
            setShowModal(true);
        } else {
            setDataDetail([]);
            setShowModal(true);
            message.warning(res?.msg ?? "Không có dữ liệu");
        }
    }

    const getDataReport = async () => {
        const params = {
            startTime: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[0])?.startOf('day').format("YYYY-MM-DD HH:mm:ss") : dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            endTime: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[1])?.endOf('day')?.format("YYYY-MM-DD HH:mm:ss") : dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            userId: form.getFieldValue("staff"),
        }
        setLoading(true);
        const res = await getReportTotal(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data]);
        } else {
            setData([]);
            message.warning(res?.msg ?? "Không có dữ liệu");
        }
    }

    const onCancel = () => {
        setShowModal(false);
    }

    const onFinish = async (data) => {
        setCurrentPage(1);
        getDataReport(1);
    };

    useEffect(() => {
        getDataUsers();
        getDataReport(1);
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="staff"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn nhân viên'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataUser.map(item => ({ label: item.fullname, value: item.id }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'date'}
                        >
                            <RangePicker
                                placeholder={["Bắt đầu", "Kết thúc"]}
                                format={"DD-MM-YYYY"}
                                showTime={false}
                                style={{ height: 40, width: '100%' }}
                                allowClear={false}
                                defaultValue={[dayjs(), dayjs()]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>

                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.code}
            />
            
            <Modal
                title={"Thông tin chi tiết"}
                open={showModal}
                onCancel={onCancel}
                width={'60%'}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        Đóng
                    </Button>
                ]}
            >
                <Table
                    className='table-book'
                    columns={columns_modal}
                    dataSource={dataDetail}
                    pagination={{
                        showSizeChanger: true
                    }}
                    rowKey={(record) => record?.code}
                />
            </Modal>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ReportBillTotalPage);