import axiosClient from "./axios.client";
import axiosTokenKiot from "./axios.tokenkiot";

export const login = (params) => {
    return axiosClient.post('authen/login', params);
}

export const saveLogUser = (params) => {
    return axiosClient.post('logUser',params);
}
export const getListBranch = (params) => {
    return axiosClient.post(`cms/category/getListBranch`, params);
}

export const addNewBranch = (params) => {
    return axiosClient.post('cms/category/addNewBranch', params);
}

export const updateBranchById = (params) => {
    return axiosClient.post('cms/category/updateBranchById', params);
}

export const getListAccount = (params) => {
    return axiosClient.post('cms/account/getListAccount', params);
}

export const addNewUser = (params) => {
    return axiosClient.post('cms/account/createNewAccount', params);
}

export const updateUserInfo = (params) => {
    return axiosClient.post('cms/account/updateUserInfo', params);
}

export const checkBillExist = (params) => {
    return axiosClient.post('cms/check/checkBillExist', params);
}

export const getReportBillDuplicate = (params) => {
    return axiosClient.post('cms/report/getReportBillDuplicate', params);
}

export const changePassword = (params) => {
    return axiosClient.post('authen/changePassword', params);
}

export const getConfig = () => {
    return axiosClient.post('cms/config/getConfig');
}

export const getReportTotal = (params) => {
    return axiosClient.post('cms/report/getReportTotal', params);
}

export const getListBillDiffDate = () => {
    return axiosClient.post('cms/report/getListBillDiffDate');
}

export const getListBillByUserId = (params) => {
    return axiosClient.post('cms/report/getListBillByUserId', params);
}