import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, message } from 'antd';
import LoadingBase from '../../components/LoadingBase';
import { changePassword} from '../../services/apis';
import '../../css/listbook-page.css';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserInfo } from '../../redux/action';

const ChangePasswordPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const userReducer = useSelector(state => state?.userReducer);
    console.log("userReducer",userReducer)
    const dispatch = useDispatch();

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const onFinish = async (values) => {
        const { currentPass, newPass, reNewPass } = values;
        
        if (newPass !== reNewPass) {
            message.error("Mật khẩu nhập lại không khớp");
            return;
        }

        const params = {
            user_id: userReducer?.data?.user_info?.id,
            old_password: currentPass,
            new_password: newPass
        };
        
        setLoading(true);
        const res = await changePassword(params);
        setLoading(false);
        
        if (res && res.status === 1) {
            message.success("Cập nhật mật khẩu thành công");
            setTimeout(() => {
                dispatch(saveUserInfo());
            }, 1000);
        } else {
            message.error(res?.msg ?? "Cập nhật mật khẩu thất bại");
        }
    };
    useEffect(() => {
    }, [])
    return (
        <div className='container-content min-height'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'currentPass'}
                        >
                            <Input.Password placeholder={'Mật khẩu hiện tại'}/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'newPass'}
                        >
                            <Input.Password placeholder={'Mật khẩu mới'}/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'reNewPass'}
                        >
                            <Input.Password placeholder={'Nhập lại mật khẩu mới'}/>
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row>
                <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Cập nhật
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            
            <LoadingBase loading={loading} />
        </div>

    );
};
export default ChangePasswordPage;